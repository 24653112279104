import React from 'react';
import AuthLayout from './AuthLayout/AuthLayout';
import NewAuth from './NewAuth/NewAuth';

const Auth = () => (
  <AuthLayout>
    <NewAuth showCloseIcon={false} />
  </AuthLayout>
);
export default Auth;
